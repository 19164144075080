import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <StyledFooter>
      This game is deeply inspired by{" "}
      <a
        href="https://neal.fun/password-game/"
        target="_blank"
        rel="noreferrer"
      >
        neal.fun
      </a>
      's The Password Game. Created by{" "}
      <a href="https://github.com/fsfregalado" target="_blank" rel="noreferrer">
        Francisco
      </a>{" "}
      using ReactJS, and styled components.
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: black;
  text-align: center;
  padding: 20px 0;
  font-size: 0.8rem;
  color: #444;
  background-color: #fff;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export default Footer;
