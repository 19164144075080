import React from "react";
import ContainerShadow from "../shared/ContainerShadow";
import styled from "styled-components";

const PasswordInput = ({ password, passwordChange, language }) => {
  return (
    <ContainerShadow>
      <Input
        type="text"
        placeholder={language === "pt_pt" ? "escreva aqui o seu palpite" : "write your guess here"}
        value={password}
        onChange={passwordChange}
      />
      <Label>{language === "pt_pt" ? "siga as regras apresentadas" : "follow the presented rules"}</Label>
    </ContainerShadow>
  );
};

const Input = styled.input`
  height: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #aeaeae;
  border-radius: 8px;
  font-size: 1rem;
`;

const Label = styled.label`
  font-size: 0.8rem;
`;

export default PasswordInput;
