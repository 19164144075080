import React from "react";
import VerificationIcon from "../shared/Icon";
import styled from "styled-components";

const RulesItem = ({ rule, password }) => {
  return (
    <>
      <ListItem key={rule.name}>
        <VerificationIcon
          status={rule.check(password) ? "fulfilled" : "unfulfilled"}
        />
        <Paragraph>{rule.message}</Paragraph>
      </ListItem>
    </>
  );
};

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
`;

const Paragraph = styled.p`
  flex: 1;
  margin: 0;
`;

export default RulesItem;
