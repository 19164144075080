import styled from "styled-components";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.$rounded ? "2px solid white" : "none")};
  padding: ${(props) => (props.$rounded ? "6px 7px" : "10px 20px")};
  border-radius: ${(props) => (props.$rounded ? "50%" : "15px")};
  background-color: ${(props) => props.$backgroundcolor || "#007bff"};
  color: white;
  font-size: ${(props) => (props.$rounded ? "1.2rem" : "1rem")};
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: ${(props) => props.$width || "auto"};
  height: ${(props) => props.$height || "auto"};
  font-weight: ${(props) => (props.$bold ? "bold" : "normal")};

  &:hover {
    background-color: ${(props) =>
      props.$backgroundcolor ? props.$backgroundcolor : "#0056b3"};
  }
`;

export default Button;
