// rules.js
import emojiRegex from "emoji-regex";

const rules = [
  {
    name: "length",
    check: (password) => /.{8,}/.test(password),
    message: "At least 8 characters",
  },
  {
    name: "uppercase",
    check: (password) => /[A-Z]/.test(password),
    message: "At least one uppercase letter",
  },
  {
    name: "number",
    check: (password) => /\d/.test(password),
    message: "At least one number",
  },
  {
    name: "specialChar",
    check: (password) => /[!@#$%^&*(),.?":{}|<>\-_]/.test(password),
    message: "At least one special character",
  },
  {
    name: "digitSum",
    check: (password) => {
      const digits = password.match(/\d/g) || [];
      const sum = digits.reduce((a, b) => a + Number(b), 0);
      return sum === 25;
    },
    message: "All digits must sum to 25",
  },
  {
    name: "seasonWeatherHoliday",
    check: (password) => {
      const seasonWeatherHolidayRegex =
        /(spring|summer|autumn|winter|rain|snow|sun|christmas|halloween|easter)/i;
      return seasonWeatherHolidayRegex.test(password);
    },
    message:
      "Must include a reference to a season, weather condition, or holiday",
  },
  {
    name: "emoji",
    check: (password) => {
      const regex = emojiRegex();
      return regex.test(password);
    },
    message: "Must contain at least one emoji",
  },
  {
    name: "color",
    check: (password) => {
      const hexColorRegex = /#[0-9a-fA-F]{6}/;
      return hexColorRegex.test(password);
    },
    message: "Must include a hexadecimal color code",
  },
];

export default rules;
