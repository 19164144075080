import "./App.css";
import Footer from "./components/shared/Footer";
import ImpossiblePassword from "./components/password/ImpossiblePassword";

function App({ language }) {
  return (
    <div className="App">
      <ImpossiblePassword language={language} />
      <Footer />
    </div>
  );
}

export default App;
