// rules.js
import emojiRegex from "emoji-regex";

const rules_pt_pt = [
  {
    name: "length",
    check: (password) => /.{8,}/.test(password),
    message: "Ter pelo menos 8 carateres",
  },
  {
    name: "uppercase",
    check: (password) => /[A-Z]/.test(password),
    message: "Ter pelo menos uma letra maiúscula",
  },
  {
    name: "number",
    check: (password) => /\d/.test(password),
    message: "Ter pelo menos um número",
  },
  {
    name: "specialChar",
    check: (password) => /[!@#$%^&*(),.?":{}|<>\-_]/.test(password),
    message: 'Ter pelo menos um carater especial (ex: !@#$%^&*(),.?":{}|<>-_)',
  },
  {
    name: "digitSum",
    check: (password) => {
      const digits = password.match(/\d/g) || [];
      const sum = digits.reduce((a, b) => a + Number(b), 0);
      return sum === 25;
    },
    message: "Os números introduzidos somados têm de dar 25",
  },
  {
    name: "seasonWeatherHoliday",
    check: (password) => {
      const seasonWeatherHolidayRegex =
        /(primavera|verão|verao|outono|inverno|chuva|neve|sol|nublado|frio|calor|natal|pascoa|páscoa|halloween|carnaval)/i;
      return seasonWeatherHolidayRegex.test(password);
    },
    message: "Incluir uma estação do ano",
  },
  {
    name: "weather",
    check: (password) => {
      const seasonWeatherHolidayRegex =
        /(inverno|chuva|neve|sol|nublado|frio|calor)/i;
      return seasonWeatherHolidayRegex.test(password);
    },
    message: "Incluir uma condição meteorológica",
  },
  {
    name: "currentMonth",
    check: (password) => {
      const month = new Date().toLocaleString("pt-PT", { month: "long" });
      return password.includes(month);
    },
    message: "Incluir o mês atual",
  },
  {
    name: "emoji",
    check: (password) => {
      const regex = emojiRegex();
      return regex.test(password);
    },
    message: "Deve incluir pelo menos um emoji",
  },
  {
    name: "city",
    check: (password) => {
      return password.includes("Ílhavo");
    },
    message: "Deve incluir a cidade onde estamos",
  },
  {
    name: "romanNumeral",
    check: (password) => {
      const regex =
        /^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
      return regex.test(password);
    },
    message: "Deve incluir um número romano",
  },
];

export default rules_pt_pt;
