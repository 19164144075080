import React, { useState } from "react";
import ContainerShadow from "../shared/ContainerShadow";
import RulesItem from "./RulesItem";
import styled from "styled-components";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const RulesList = ({ rules, password, shownRules }) => {
  const fulfilledRules = shownRules.filter((index) =>
    rules[index].check(password),
  );
  const unfulfilledRules = shownRules.filter(
    (index) => !rules[index].check(password),
  );
  const [parent, enableAnimations] = useAutoAnimate();

  const [helpDisplayed, setHelpDistplayed] = useState(true);

  return (
    <ContainerShadow>
      <UnorderedList ref={parent}>
        {unfulfilledRules &&
          unfulfilledRules.map(
            (index) => {
              return (
                <RulesItem
                  key={index}
                  rule={rules[index]}
                  password={password}
                />
              );
            },
            [password],
          )}

        {fulfilledRules &&
          fulfilledRules.map(
            (index) => {
              return (
                <RulesItem
                  key={index}
                  rule={rules[index]}
                  password={password}
                />
              );
            },
            [password],
          )}

        {/* {rules.map((rule, index) => {
          // Check if all previous conditions were fulfilled
          const allPreviousFulfilled = Array.from({ length: index }, (_, i) =>
            rules[i].regex.test(password),
          ).every(Boolean);

          // Only show the rule if it's the first one, the previous one is fulfilled, or it has been shown before
          if (
            index === 0 ||
            (rules[index - 1].regex.test(password) && allPreviousFulfilled) ||
            shownRules.includes(index)
          ) {
            // return <RulesItem rule={rule} password={password} />;
          }
          return null;
        })} */}
      </UnorderedList>
    </ContainerShadow>
  );
};

const UnorderedList = styled.ul`
  li {
    margin: 0.5rem 0;
  }
  last-child {
    margin-bottom: 0;
  }
  liststyletype: "none";
  padding: 0;
  margin: 0;
`;

export default RulesList;
