import React, { useState, useEffect } from "react";
import PasswordInput from "./PasswordInput";
import rules_eng from "../rules/rules_data";
import rules_pt_pt from "../rules/rules_data_pt_pt";
import Container from "../shared/Container";
import styled from "styled-components";
import Button from "../shared/ButtonStyled";
import { MdReplay } from "react-icons/md";
import RulesList from "../rules/RulesList";

const ImpossiblePassword = ({ language }) => {
  const [password, setPassword] = useState("");
  const [lastFulfilledRuleIndex, setLastFulfilledRuleIndex] = useState(0);
  const [shownRules, setShownRules] = useState([]);
  const [allRulesFulfilled, setAllRulesFulfilled] = useState(false);

  let rules;
  {
    language ? (rules = rules_pt_pt) : (rules = rules_eng);
  }

  useEffect(() => {
    // Find the last fulfilled rule
    for (let i = 0; i < rules.length; i++) {
      if (!rules[i].check(password)) {
        setLastFulfilledRuleIndex(i);
        break;
      }
      // If all rules are fulfilled, set lastFulfilledRuleIndex to the length of rules
      if (i === rules.length - 1) {
        setLastFulfilledRuleIndex(rules.length);
      }
    }

    // Update shown rules
    const newShownRules = [...shownRules];
    for (let i = 0; i <= lastFulfilledRuleIndex; i++) {
      if (!newShownRules.includes(i) && i !== rules.length) {
        newShownRules.push(i);
      }
    }
    setShownRules(newShownRules);
  }, [password, rules, lastFulfilledRuleIndex]);

  const handleChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const allFulfilled = rules.every((rule) => rule.check(newPassword));
    setAllRulesFulfilled(allFulfilled);
  };

  const handleReset = () => {
    setPassword("");
    setAllRulesFulfilled(false);
    setShownRules([]);
    setLastFulfilledRuleIndex(0);
  };

  return (
    <div>
      <Header1>{language === "pt_pt" ? "O jogo da password impossível 🔓" : "The Impossible Password Game 🔓"}</Header1>
      <Container>
        <PasswordInput password={password} passwordChange={handleChange} language={language} />
        <RulesList
          rules={rules}
          password={password}
          lastFulfilledRuleIndex={lastFulfilledRuleIndex}
          shownRules={shownRules}
        />
      </Container>
      {allRulesFulfilled && (
        <CenteredDiv>
          <p>
          {language === "pt_pt" ? "Parabéns 🎉 Cumpriu todas as regras. Quer voltar a jogar?" : "Congratulations 🎉 You fulfilled all the rules. Want to play again?"}
            
          </p>
          <Button onClick={handleReset}>
            Play again <MdReplay />
          </Button>
        </CenteredDiv>
      )}
    </div>
  );
};

const Header1 = styled.h1`
  margin-bottom: 4rem;
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 5rem;
  }
`;

export default ImpossiblePassword;
