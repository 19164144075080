import React from "react";
import styled from "styled-components";
import { MdCheck, MdClose, MdHelp } from "react-icons/md";

const VerificationIcon = (props) => {
  return (
    <StyledRoundedIcon {...props}>
      {props.fulfilled ? "✓" : "✕"}
    </StyledRoundedIcon>
  );
};

const StyledRoundedIcon = styled.div`
  height: 1rem;
  width: 1rem;
  font-size: 0.8rem;
  border-radius: 50%;
  border: 1px solid
    ${(props) => {
      switch (props.status) {
        case "fulfilled":
          return "#1bac76";
        case "unfulfilled":
          return "#DF5D51";
        case "unknown":
        default:
          return "#FFF";
      }
    }};
  background-color: ${(props) => {
    switch (props.status) {
      case "fulfilled":
        return "#1BAC76";
      case "unfulfilled":
        return "#FFF";
      case "help":
      default:
        return "#FFF";
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => {
    switch (props.status) {
      case "fulfilled":
        return "#FFF";
      case "unfulfilled":
        return "#DF5D51";
      case "help":
      default:
        return "#F5B015";
    }
  }};
  margin-right: 0.8rem;
  transition: background-color 0.3s ease;
`;

export default VerificationIcon;
