import styled from "styled-components";

const Container = styled.section`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export default Container;
