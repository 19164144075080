import styled from "styled-components";

const ContainerShadow = styled.section`
  border-radius: 8px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 43%;
  display: flex;
  flex-flow: column wrap;
  height: fit-content;

  @media (max-width: 768px) {
    width: initial;
    margin-bottom: 2rem;
  }
`;

export default ContainerShadow;
